import React,{useState,useEffect} from 'react';
import Layout from '../components/common/layout';
import Header from '../components/common/Header';
import Footer   from '../components/common/Footer';
import { Helmet } from "react-helmet";
import { Link } from 'gatsby';
import logo from '../images/logo-top.svg';
import img1 from '../images/404.svg';

const NotFoundPage = () => {
  const [pathName,setPathName] = React.useState('');
  useEffect(function mount() {
    setPathName(window.location.pathname);
    })
  return (
  <Layout>
        <Helmet>            
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <title>LaundryToGo - Not Found</title> 
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=yes" />
            <meta name="author" content="LaundryToGo - We Make Laundry Easy" />
            <meta name="description" content="LaundryToGo Page Not Found" />
        </Helmet>
        <div className="container">
            <div className="row" style={{marginTop: '100px'}}>
                <div className="col-md-6">
                    <Link to="/" className="navbar-logo" style={{zIndex: '10001'}}>
                            <img src={logo} className="logo-web" alt="LaundryToGo" />
                    </Link>
                    <br/>
                    <h1 style={{color: '#0f056b'}}>404.</h1>
                    <p style={{color: '#000', fontSize: '22px'}}>
                      <span style={{color: '#0f056b', fontSize: '22px', fontWeight: '500'}}>
                      <br/>
                      <br/>
                        The requested URL {pathName} was not found on this server.
                      </span>
                      <br/>Try Scheduling Again.
                    </p>
                </div>
                <div className="col-md-6 desktopView">
                <br/>
                <br/>
                  <img src={img1} style={{width: '100%'}} alt="LaundryToGo" />                   
                </div>
            </div>
        </div>
  </Layout>
)};

export default NotFoundPage;
